import { render, staticRenderFns } from "./BusinessOffice.vue?vue&type=template&id=ef9e754e&scoped=true&"
import script from "./BusinessOffice.vue?vue&type=script&lang=js&"
export * from "./BusinessOffice.vue?vue&type=script&lang=js&"
import style0 from "./BusinessOffice.vue?vue&type=style&index=0&id=ef9e754e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef9e754e",
  null
  
)

export default component.exports